import React from 'react';
import PropTypes from 'prop-types'
import { useStaticQuery, graphql } from "gatsby"
import SectionTitle from '../../../components/shared/section-title'
import Blog from '../../../components/blog/layout-one'
import { BlogSectionWrap, BlogInner } from './blog.stc'

const BlogSection = ({ section }) => {
    const blogQueryData = useStaticQuery(graphql`
        query HomeBlogDataQuery {
            homedataJson(id: {eq: "recent_post_section_content"}) {
                title
                subtitle
            }
            allDatoCmsBlog (limit: 2, sort: {order: DESC, fields: [date]}) {
                edges {
                  node {
                    id
                    title
                    author
                    slug
                    desc
                    date(formatString: "Do MMMM, YYYY" locale:"tr")
                    image {
                      fluid(maxWidth: 600, maxHeight: 630) {
                          ...GatsbyDatoCmsFluid_noBase64
                        }
                    }
                  }
                }
            }
        }
    `);
    const blogSecData = blogQueryData.homedataJson;
    const blogs = blogQueryData.allDatoCmsBlog.edges;

    return (
        <BlogSectionWrap {...section}>
            <div className="col-1 offset-1">
                <SectionTitle
                    title={blogSecData.title}
                    subtitle={blogSecData.subtitle}
                />
            </div>
            <BlogInner className="col-4 offset-1">
                <div className="row">
                    {blogs.map((blog, i) => (
                        <div className="col-2" key={`${blog.node.slug}-${i}`}>
                            <Blog
                                title={blog.node.title}
                                date={blog.node.date}
                                author={blog.node.author}
                                id={blog.node.id}
                                path={blog.node.slug}
                                excerpt={blog.node.desc}
                                image={blog.node.image.fluid}
                            />
                        </div>
                    ))}
                </div>
            </BlogInner>
        </BlogSectionWrap>
    )
}

BlogSection.propTypes = {
    section: PropTypes.object
}

BlogSection.defaultProps = {
    section: {
        backgroundColor: '#f8f8f8'
    },
}

export default BlogSection;