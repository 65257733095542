import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link } from 'gatsby';
import Img from "gatsby-image"

const PostCardWrapper = styled.div`
  width: 33%;
  padding: 0.5rem 0;
  padding-left: 24px;
  @media screen and (max-width: 600px) {
    width: 100%;
    padding-top: 0;
    padding-left: 0;
  }
  .date {
    span {
      text-transform: uppercase;
      font-size: 1.2rem;
      letter-spacing: 0.5px;
    }
  }
  .postTitle {
    width: 90%;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
    color: #000000;
    margin: 0rem 0;
    h4 {
        font-size: 12px;
        text-transform: capitalize;
        letter-spacing: 1.3px;
      }
  }
  .button {
    margin-top: 4rem;
  }
`;

const PostImg = styled(Img)`
  position: absolute;
  left: 0;
  :hover {
    opacity: 0.5;
    -webkit-transition: opacity 0.3s ease-in-out;
  -moz-transition: opacity 0.3s ease-in-out;
  -ms-transition: opacity 0.3s ease-in-out;
  -o-transition: opacity 0.3s ease-in-out;
  transition: opacity 0.3s ease-in-out;
}
  cursor: auto;
  -webkit-tap-highlight-color: rgba(255,255,255,0);
  box-sizing: inherit;
  font-size: inherit;
  margin: 0px;
  padding: 0px;
  position: relative;
  height: 200px;
  margin-bottom: 30px;
    @media screen and (max-width: 600px) {
      height: 250px;
    }
  & > img {
    object-fit: "cover" !important;
    object-position: "50% 50%" !important;
  }
`

const PostCard = ({ data }) => {
  return (
    <PostCardWrapper>
      <Link to={`/projeler/${data.node.id}`} title={data.node.title}>
        <div className='box'>
          <PostImg backgroundColor={"#2B2B2B"} fluid={data.node.featuredimage.fluid} />
        </div>
        <div className="postTitle">
          <h4>{data.node.title}</h4>
        </div>
      </Link>
    </PostCardWrapper>
  );
};

PostCard.propTypes = {
  data: PropTypes.object,
};

export default PostCard;
