import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import PostCardGroup from './PostCardGroup';
import SectionTitle from '../components/shared/section-title'
import Button from '../components/shared/button'
import Container from './Container';
import { Link } from "gatsby"

const RecentWrapper = styled.div`
  margin-top: 2rem;
  section {
    flex-direction: column;
    .title, .posts {
      width: 1280px;
      max-width: 100%;
      margin: auto;
      h2 {
        margin: 2rem 0;
        @media screen and (max-width: 600px) {
          font-size: 1.2rem;
        }
      }
    }
  }
`;

const Recent = ({ posts }) => (
  <RecentWrapper>
    <Container>
      <SectionTitle
        title="Projeler"
      />
      <div className="posts">
        <PostCardGroup posts={posts} topBorder={true} />
        <br />
        <Button as={Link} to="/projeler" >TÜM PROJELER</Button>
        <br /><br />
      </div>
    </Container>
  </RecentWrapper >
);

Recent.propTypes = {
  posts: PropTypes.array.isRequired,
};


export default Recent;
