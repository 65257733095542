import React from 'react'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql } from "gatsby"
import SlickSlider from '../../../components/shared/slick'
import Testimonial from '../../../components/testimonial'
import { TestimonialWrap } from './testimonial.stc'

const TestimonialSection = ({ section, options }) => {
    const testimonialData = useStaticQuery(graphql`
        query TestimonailQuery {
            allDatoCmsTestimonial {
                edges {
                  node {
                    id
                    name
                    role
                    company
                    country
                    review
                    image {
                        fixed(width: 88 ) {
                            ...GatsbyDatoCmsFixed
                        }
                    }
                  }
                }
              }
        }
    `);
    const testimonilas = testimonialData.allDatoCmsTestimonial.edges;
    return (
        <TestimonialWrap {...section}>
            <div className="row align-items-center">
                <div className="col-6">
                    <SlickSlider options={options}>
                        {testimonilas.map(data => (
                            <div className="item" key={data.node.id}>
                                <Testimonial
                                    author_name={data.node.name}
                                    author_role={data.node.role}
                                    company={data.node.company}
                                    country={data.node.country}
                                    author_image={data.node.image.fixed}
                                    review={data.node.review}
                                />
                            </div>
                        ))}
                    </SlickSlider>
                </div>
            </div>
        </TestimonialWrap>
    )
}

TestimonialSection.propTypes = {
    section: PropTypes.object
}

TestimonialSection.defaultProps = {
    section: {
        backgroundColor: '#fff'
    },
    options: {
        slidesToShow: 1,
        dots: true
    }
}

export default TestimonialSection
