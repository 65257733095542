import React from 'react'
import PropTypes from 'prop-types'
import { ClientWrap } from './client.stc'

export const Client = ({ link, client_image, ...props }) => {
    return (
        <ClientWrap {...props}>
            <a target="_blank" href={link} rel="noreferrer">
                <img src={client_image.src} alt="Konya Mimar" />
            </a>
        </ClientWrap>
    )
}

Client.propTypes = {
    link: PropTypes.string,
    image: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object
    ])
}
