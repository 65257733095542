import React from "react"
import { graphql } from "gatsby"

import SEO from "../components/PageSEO"
import Layout from "../containers/layout/layout"
import Hero from "../containers/home-banner/hero"
import Story from "../containers/home-banner/story"
import TestimonialSection from "../containers/home-banner/testimonial"
import ClientSection from "../containers/home-banner/clients"
import VideoSection from "../containers/home-banner/video"
import BlogSection from "../containers/home-banner/blog"
import Recent from "../components/Recent"

const IndexPage = ({ data: { blog } }) => {
  return (
    <Layout>
      <SEO
        title="Mimar (Konya) | En İyi Mimarlık Ofisi – Furkan Acar Mimarlık"
        description="Konya içinde mimar olan Furkan Acar’a, iç mimarlık, villa proje, mimari proje ve en iyi mimarlık ofisi bilgilerini arayanlar, 2021 fiyatlarını sorabilir."
      />
      <Hero />
      <Story />
      <Recent posts={blog.edges} />
      <TestimonialSection />
      <ClientSection />
      <VideoSection />
      <BlogSection />
    </Layout>
  )
}
export default IndexPage

export const homeQuery = graphql`
  {
    blog: allDatoCmsProject(limit: 9) {
      edges {
        node {
          id
          title
          sector
          client
          featuredimage {
            fluid(maxWidth: 1760, maxHeight: 990) {
              ...GatsbyDatoCmsFluid_noBase64
            }
          }
        }
      }
    }
  }
`
