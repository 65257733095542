import styled from 'styled-components'
import { device } from '../../../theme'

export const ClientWrap = styled.li`
    flex-basis: 20%;
    text-align: center;
    height: 214px;
    line-height: 214px;
    @media ${device.small}{
        flex-basis: 50%;
        height: 150px;
        line-height: 150px;
    }
    a{
        display: block;
        &:hover{
            transform: scale(1.1);
            img{
                opacity: 1;
            }
        }
        img{
            opacity: 0.7;
            transition: 0.3s;
            max-height: 110px;
            max-width: 150px;
            @media ${device.small}{
                max-height: 70px;
                max-width: 70px;
            }
        }
    }
`;